<script setup>
const { data, error } = await useCmsFetch("entry/index");

if (error.value) {
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
    fatal: true,
  });
}

useHead({
  title: ".the tech collective | " + data.value?.seoTitle,
  meta: [
    { property: "og:title", content: data.value?.seoTitle },
    { name: "image", property: "og:image", content: data.value?.seoImage },
    { name: "description", content: data.value?.seoDescription },
    { name: "robots", content: data.value?.seoRobots },
  ].filter((item) => item.content),
  link: [{ rel: "canonical", href: data.value?.seoCanonical }].filter(
    (item) => item.href,
  ),
});

const { setGlobalTheme } = useTheme();
setGlobalTheme(data.value.theme);
</script>

<template>
  <div>
    <div class="index" v-if="data">
      <div class="page-content">
        <section-hero
          v-if="data"
          :body="data.body"
          :heading="data.heading"
          :media="data.media"
        />
        <component-sections v-if="data" :sections="data.sections" />
      </div>
      <!-- <code>
        <pre>{{ data }}</pre>
      </code> -->
    </div>
  </div>
</template>
